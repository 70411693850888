<template>
  <div class="content">
    <el-row>
      <el-col :span="24" :xs="24">
        <div class="ipt">
          <el-input
            size="mini"
            clearable
            placeholder="请输入运单号"
            v-model="vm.search.waybillCode"
          >
            <el-button slot="append" icon="el-icon-search" @click="search"
              >查询</el-button
            >
          </el-input>
        </div>
        <div v-if="show == true" class="center">
          <p class="ap">
            订单信息
          </p>
          <div style="font-size:14px">
            <span style="padding-right:10px">收货信息:</span
            >{{ listData.customerContacts }},
            {{ listData.customerContactsPhone }}, {{ listData.customerAddress }}
          </div>
          <div style="font-size:14px">
            <span style="padding-right:10px">订单编号:</span
            ><span>{{ listData.waybillCode }}</span>
          </div>
          <div style="font-size:14px">
            <span style="padding-right:10px">创建时间:</span
            ><span>{{ listData.createTime | data }}</span>
          </div>
          <div style="font-size:14px">
            <span style="padding-right:10px">发货时间:</span
            ><span>{{ listData.deliveryDate | data }}</span>
          </div>

          <!-- <span>收货信息</span>
          <span class="s1">运单编号:{{ listData.waybillCode }};</span>
          <span class="s1">收件名称:{{ listData.customerName }};</span>
          <span class="s1">收件联系人:{{ listData.customerContacts }};</span>
          <span class="s1"
            >收件联系电话:{{ listData.customerContactsPhone }};</span
          >
          <span class="s1">寄件名称:{{ listData.enterpriseName }};</span>
          <span class="s1">寄件负责人:{{ listData.enterpriseDirector }};</span>
          <span class="s1"
            >寄件负责人电话:{{ listData.enterpriseDirectorMobile }}</span
          > -->
        </div>
        <div class="footer">
          <span v-if="data.total > 0">收件状态:</span>
          <!-- <span v-if="data.total == 0">待收件</span> -->
          <span v-if="data.total > 0">已签收</span>
          <div style="margin-top:20px;margin-left:-20px">
            <el-timeline :reverse="reverse">
              <el-timeline-item
                v-for="(item, index) in data.rows"
                :key="index"
                :color="item.color"
                :timestamp="item.createTime | data"
              >
                <!-- <span v-if="item.siteTrailState != 3"
                    >【{{ item.siteName }}】</span
                  > -->
                <div v-if="item.siteTrailState == 1">已收货</div>
                <span v-if="item.siteTrailState == 1"
                  >快递员（{{ item.responser }}）收件，电话号码：（{{
                    item.responserTel
                  }}）</span
                >
                <div v-if="item.siteTrailState == 2">运输中</div>
                <span v-if="item.siteTrailState == 2"
                  >快件离开【{{ data.rows[0].siteName }}】，发往【{{
                    item.siteName
                  }}】</span
                >

                <div v-if="item.siteTrailState == 4">派送中</div>
                <span v-if="item.siteTrailState == 4"
                  >快件已到达【{{ item.siteName }}】，快递员（{{
                    item.responser
                  }}）正在派送请保持手机畅通，并耐心等待。</span
                >
                <div v-if="item.siteTrailState == 3">已签收</div>
                <span v-if="item.siteTrailState == 3">您的快递已签收。</span>
                <div v-if="item.siteTrailState != 3">
                  <span style="margin-right: 10px;"
                    >站点负责人：{{ item.responser }}</span
                  >
                  <span>联系电话：{{ item.responserTel }}</span>
                </div>
              </el-timeline-item>
            </el-timeline>
          </div>
        </div>
      </el-col>
    </el-row>
  </div>
</template>
<script>
import { BASE_URL, FACTORY_URL, MACHINE_API } from "../config";

export default {
  name: "waybill-search",
  data() {
    return {
      show: false,
      reverse: true,
      vm: {
        search: {
          WaybillCode: "",
        },
        pageIndex: 1,
        pageSize: 10,
        noCount: false,
      },
      listData: "",
      data: "",
      status: false,
    };
  },
  mounted() {},
  methods: {
    list() {
      let url = `${BASE_URL}/api/home/PagingSendListSelect`;
      this.axios({
        url: url,
        method: "POST",
        data: this.vm,
      }).then((res) => {
        if (res.data.content.content.total > 0) {
          this.show = true;
          this.listData = res.data.content.content.rows[0];
        } else {
          this.$message.error("运单号错误！");
          this.show = false;
        }
      });
    },
    search() {
      this.status = true;
      this.list();
      let url = `${BASE_URL}/api/home/TrackingDetail`;
      this.axios({
        url: url, //获取菜单列表
        method: "POST",
        data: this.vm,
      }).then((res) => {
        console.log(res.data);
        this.data = res.data.content;
        if (res.data.content.total > 0) {
          if (this.data.rows[this.data.total - 1].siteTrailState == 3) {
            this.data.rows[this.data.total - 1].color = "blue";
          }
        }
      });
    },
  },
};
</script>
<style scoped lang="scss">
.content {
  width: 100%;
}
.ap {
  text-align: left;
  color: #6282f5;
  font-weight: 600;
}
.center {
  text-align: left;
}
.s1 {
  padding-left: 10px;
}
.ipt {
  text-align: left;
  width: 300px;
}
.footer {
  text-align: left;
}
</style>
